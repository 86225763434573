import {Drawer, Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      zIndex: 8,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      zIndex: 8,
      padding: theme.spacing(2),
    },
  })
)

export function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const {children} = props
  const classes = useStyles()

  const drawerContents = (
    <div>
      <div className={classes.toolbar} />
      {children}
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      <MobileDrawer {...props}>{drawerContents}</MobileDrawer>
      <DesktopDrawer {...props}>{drawerContents}</DesktopDrawer>
    </nav>
  )
}

export type ResponsiveDrawerProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleOpen: () => void
}

function MobileDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    // The implementation can be swapped with js to avoid SEO duplication of links.

    <Drawer
      variant='temporary'
      anchor='left'
      open={isOpen}
      onClose={toggleOpen}
      sx={{display: {lg: 'none', md: 'block'}}}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {children}
    </Drawer>
  )
}

function DesktopDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      sx={{display: {lg: 'block', md: 'none'}}}
      variant='temporary'
      open={isOpen}
      onClose={toggleOpen}
    >
      {children}
    </Drawer>
  )
}
