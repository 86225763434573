import {useMutation, useQueryClient} from 'react-query'
import {AllReports} from '../../models'
import {reportApi} from '../../api-interface'
import {getReportsKey} from '..'
type DeleteReportContext = {
  previousReports: AllReports
}
export function useDeleteReport() {
  const queryClient = useQueryClient()

  return useMutation(reportApi.deleteReport, {
    async onMutate(newReportId: string): Promise<DeleteReportContext> {
      await queryClient.cancelQueries(getReportsKey)

      let previousReports: AllReports = queryClient.getQueryData(getReportsKey)
      queryClient.setQueryData(getReportsKey, (old: AllReports) => {
        if (!old) {
          old = {
            ownedReports: [],
            sharedReports: [],
            defaultReports: [],
            licenseReports: [],
          }
        }
        return {
          ownedReports: old.ownedReports.filter(
            report => report.id !== newReportId
          ),
          sharedReports: old.sharedReports.filter(
            report => report.id !== newReportId
          ),
          defaultReports: old.defaultReports.filter(
            report => report.id !== newReportId
          ),
          licenseReports: old.licenseReports.filter(
            report => report.id !== newReportId
          ),
        }
      })

      return {previousReports}
    },
    onError(err, newAppRegId, context: DeleteReportContext) {
      queryClient.setQueryData(getReportsKey, context.previousReports)
    },
    onSettled() {
      queryClient.invalidateQueries(getReportsKey)
    },
  })
}
