import {useMutation, useQueryClient} from 'react-query'
import {reportApi} from '../../api-interface'
import {AllReports} from '../../models'
import {getReportsKey} from '../queries'

type CreateReportContext = {
  previousReports: AllReports
}

export function useUpdateReport() {
  const queryClient = useQueryClient()

  return useMutation(reportApi.updateReport, {
    async onMutate(newReport): Promise<CreateReportContext> {
      await queryClient.cancelQueries(getReportsKey)

      let previousReports: AllReports = await queryClient.getQueryData(
        getReportsKey
      )
      //console.log('Previous reports: ', previousReports)
      // Have to destructure the ApiReport out of the ReportToCreate
      const {appRegistrationNodeId, viewers, ...newApiReport} = newReport
      queryClient.setQueryData(getReportsKey, (old: AllReports) => {
        if (!old) {
          return {
            ownedReports: [],
            sharedReports: [],
            defaultReports: [],
            licenseReports: [],
          }
        }
        return {
          ownedReports: [...old.ownedReports, newApiReport],
          sharedReports: old.sharedReports,
          defaultReports: old.defaultReports,
          licenseReports: old.licenseReports,
        }
      })

      // Return the context passed on onError
      return {previousReports}
    },
    onError(err, newUser, context: CreateReportContext) {
      queryClient.setQueryData(getReportsKey, context.previousReports)
    },
    onSettled() {
      queryClient.invalidateQueries(getReportsKey)
    },
  })
}
