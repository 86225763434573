import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getReportsKey = 'get-reports'

export function useGetReports() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getReportsKey, licenseId],
    () => reportApi.getReports(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
