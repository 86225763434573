import React from 'react'
import {Switch, Route, useRouteMatch} from 'react-router-dom'

import {ReportDashboard} from './report-dashboard'
import {ReportView} from './report-view'

export function ReportsPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:id/:name`}>
        <ReportView />
      </Route>
      <Route>
        <ReportDashboard />
      </Route>
    </Switch>
  )
}
