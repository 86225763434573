import {AxiosError} from 'axios'
import {
  ReportToCreate,
  ReportConnectionStatus,
  ApiReport,
  GetReportViewersResponse,
  ApiGroupReport,
} from '../models'
import {getAxios, endpoints, getUrl} from './util'

export const reportApi = {
  async createReport(report: ReportToCreate) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.reports,
      data: report,
    })
  },
  async updateReport(report: ReportToCreate) {
    let axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.reports,
      data: report,
    })
  },
  async getReports(licenseId: string) {
    let axios = await getAxios()

    // Note: If we want to request multiple users, the ids have to be sent in comma separated.

    let response
    try {
      response = await axios({
        method: 'get',
        url: getUrl(endpoints.reports, {licenseId}),
      })
    } catch (e) {
      let error: AxiosError = e

      if (error.response) {
        // TODO
        // let status = e.response.status
      }
      return {ownedReports: [], sharedReports: [], defaultReports: []}
    }

    const ownedReports: ApiReport[] = response.data.ownedReports
    const sharedReports: ApiReport[] = response.data.sharedReports
    const defaultReports: ApiReport[] = response.data.defaultReports
    const licenseReports: ApiReport[] = response.data.licenseReports
    return {ownedReports, sharedReports, defaultReports, licenseReports}
  },

  async getReportsByUser(
    licenseId: string,
    userId: string
  ): Promise<{
    reportsFromGroups: ApiGroupReport[]
    reportsFromUser: ApiReport[]
  }> {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: getUrl(endpoints.userReports, {licenseId, userId}),
    })

    const allReportsResponse = response.data

    if (!allReportsResponse.success) {
      ////console.log('Error getting user reports')
      return {reportsFromGroups: [], reportsFromUser: []}
    }

    return {
      reportsFromGroups:
        (allReportsResponse.reportsFromGroups as ApiGroupReport[]) || [],
      reportsFromUser:
        (allReportsResponse.reportsFromUser as ApiReport[]) || [],
    }
  },

  async deleteReport(reportId: string) {
    let deleteData = {
      reportId: reportId,
    }
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.reports,
      data: deleteData,
    })
    return reportId
  },

  async testReportConnection(
    pbiWorkspaceId: string,
    pbiReportId: string,
    appRegistrationNodeId: string
  ): Promise<ReportConnectionStatus> {
    let axios = await getAxios()

    try {
      let response = await axios({
        method: 'post',
        url: endpoints.testConnection,
        data: {
          pbiWorkspaceId,
          pbiReportId,
          appRegistrationNodeId,
        },
      })

      if (
        (response.status === 200 &&
          response.data.isEffectiveIdentityRequired) ||
        response.data.isEffectiveIdentityRolesRequired
      ) {
        return ReportConnectionStatus.RowLevelSecurity
      }
      return response.status
    } catch (e) {
      return e.response.status
    }
  },

  async getViewers(
    reportId: string,
    licenseId: string
  ): Promise<GetReportViewersResponse> {
    const axios = await getAxios()

    const response = await axios.get<{payload: GetReportViewersResponse}>(
      getUrl(endpoints.reportViewers, {
        reportId,
        licenseId,
      })
    )

    return response.data.payload
  },

  async addViewers(
    reportId: string,
    viewerIds: string[],
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.reportViewers,
      data: {
        reportId,
        viewerIds,
        licenseId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },

  async addViewerToReports(
    reportIds: string[],
    viewerId: string,
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'put',
      url: endpoints.reportViewers,
      data: {
        reportIds,
        viewerId,
        licenseId,
      },
    })

    const {success, errorMessage} = response.data

    if (!success) throw new Error(errorMessage)
  },

  async removeViewers(
    reportId: string,
    viewerId: string,
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.reportViewers,
      data: {
        reportId,
        viewerId,
        licenseId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },

  async addDefault(reportId: string, licenseId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.reportDefault,
      data: {
        reportId: reportId,
        licenseId: licenseId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },
  async removeDefault(reportId: string, licenseId: string): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.reportDefault,
      data: {
        reportId: reportId,
        licenseId: licenseId,
      },
    })

    const {success, errorMessage} = response.data
    if (!success) throw new Error(errorMessage)
  },
}
