import {useState, FC} from 'react'
import {
  useCurrentUserInfo,
  useDeleteUsers,
  useGetLicense,
} from '../../../../hooks'
import {User} from '../../../../models'
import {ExistingItem, TabLayoutModal} from '../../shared'
import {SingleUserForm} from './single-user-form'
import {Typography} from '@mui/material'
import {LinkedUserItems} from './linked-user-items'
import {formatDate} from '../../../../utils'

export const ExistingUser: FC<ExistingUserProps> = (
  props: ExistingUserProps
) => {
  const {
    user,
    editMode,
    onCheck,
    isSelected,
    setToastOpen,
    setToastMessage,
    setToastVariant,
  } = props
  const {mutate: deleteUsers} = useDeleteUsers()
  const {
    data: {
      user: {email: currentUserEmail},
    },
  } = useCurrentUserInfo()
  const {data: license} = useGetLicense()
  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleSave() {
    setIsModalOpen(false)
  }

  const isCurrentUser = user.email === currentUserEmail

  return (
    <>
      <ExistingItem
        key={`${user.email} ${user.firstName} ${user.lastName}`}
        name={`${user.firstName} ${user.lastName}`}
        id={`${user.email} ${
          user.userRole && user.userRole !== 'viewer'
            ? `(${user.userRole})`
            : ''
        }`}
        checkboxProps={{
          display: editMode && user.userRole !== 'owner' && !isCurrentUser,
          onCheck: (checked: boolean) => onCheck(user.id, checked),
          checked: isSelected,
        }}
        onDelete={
          user.userRole === 'owner' || user.email === currentUserEmail
            ? void 0
            : () => {
                deleteUsers({
                  userIds: [user.email],
                  licenseId: license.id,
                })
              }
        }
        onEdit={
          user.userRole === 'owner' || user.email === currentUserEmail
            ? void 0
            : () => {
                setIsModalOpen(true)
              }
        }
      >
        {user.createdDate && (
          <Typography variant='caption'>
            Created on : {formatDate(user.createdDate)}
          </Typography>
        )}

        <LinkedUserItems user={user} />
      </ExistingItem>

      <TabLayoutModal
        title='Edit User'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <SingleUserForm
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          isEdit={true}
          userToEdit={user}
          setToastMessage={setToastMessage}
          setToastOpen={setToastOpen}
          setToastVariant={setToastVariant}
        />
      </TabLayoutModal>
    </>
  )
}

type ExistingUserProps = {
  user: User
  editMode: boolean
  onCheck: (id: string, checked: boolean) => void
  isSelected: boolean
  setToastOpen: (value: boolean) => void
  setToastMessage: (value: string) => void
  setToastVariant: (value: 'primary' | 'success' | 'warning' | 'error') => void
}
