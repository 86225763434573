import {Theme, Typography} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {ExistingItem, LinkItemsMenu, TabLayoutModal} from '../../shared'
import {AppRegistrationState} from '../../../../models'
import {
  useDeleteAppReg,
  useGetLinkedReportsByAppReg,
  useGetReports,
  useLinkReportsToAppReg,
} from '../../../../hooks'
import {LinkedReports} from './linked-reports'
import {useState} from 'react'
import {AddAppRegistrationForm} from './add-app-registration-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkedReportsTitle: {
      marginRight: theme.spacing(1),
    },
  })
)

export function ExistingAppRegistration(props: ExistingAppRegistrationProps) {
  const {appRegistration, isDisabled} = props
  const classes = useStyles()

  const {mutate: deleteAppReg} = useDeleteAppReg()
  const {data: allReports} = useGetReports()
  const reports = allReports?.licenseReports

  const {mutate: linkReportsToAppReg} = useLinkReportsToAppReg()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {data: linkedReportsByAppReg, refetch: getLinkedReports} =
    useGetLinkedReportsByAppReg(appRegistration.id)

  const reportsLinkedToAnyAppReg = linkedReportsByAppReg
    ? [].concat(...Object.values(linkedReportsByAppReg))
    : []
  const reportsLinkedToNoAppReg = reports
  ? reports.filter(r => !reportsLinkedToAnyAppReg.includes(r.id))
  : reports
    

  function handleDelete() {
    deleteAppReg(appRegistration.id)
  }

  function handleLinkReports(reportIds: string[]) {
    linkReportsToAppReg({appRegId: appRegistration.id, reportIds})
    getLinkedReports()
  }

  return (
    <>
      <ExistingItem
        key={appRegistration.id}
        name={appRegistration.name}
        id={appRegistration.appRegistrationId}
        onDelete={handleDelete}
        onEdit={isDisabled ? null : () => setIsModalOpen(true)}
      >
        <Typography
          variant='body1'
          display='inline'
          className={classes.linkedReportsTitle}
        >
          Linked Reports
        </Typography>
        <LinkItemsMenu
          onConfirm={handleLinkReports}
          addButtonLabel='Link Reports'
          availableItems={reportsLinkedToNoAppReg}
          placeHolderText='Search reports'
        />
        <LinkedReports appRegistration={appRegistration} />
      </ExistingItem>

      <TabLayoutModal
        title='Edit App Registrations'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <AddAppRegistrationForm
          onClose={() => setIsModalOpen(false)}
          onSave={() => setIsModalOpen(false)}
          isEdit={true}
          appRegistrationToEdit={appRegistration}
        />
      </TabLayoutModal>
    </>
  )
}

type ExistingAppRegistrationProps = {
  appRegistration: AppRegistrationState
  isDisabled?: boolean
}
