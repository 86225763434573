import {useMutation} from 'react-query'
import {reportApi} from '../../api-interface'

export const testReportConnectionKey = 'test-report-connection'

export type TestReportConnectionPayload = {
  pbiWorkspaceId: string
  pbiReportId: string
  appRegistrationNodeId: string
}

export function useTestReportConnection() {
  return useMutation((payload: TestReportConnectionPayload) =>
    reportApi.testReportConnection(
      payload.pbiWorkspaceId,
      payload.pbiReportId,
      payload.appRegistrationNodeId
    )
  )
}
