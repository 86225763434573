import {makeStyles} from '@mui/styles'
import {Theme, Typography, LinearProgress} from '@mui/material'
import {ExistingItem} from '../../shared'
import {License} from '../../../../models'

const useStyles = makeStyles((theme: Theme) => ({
  progressTitle: {
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))

type SubscriptionProps = {
  name: string
  description?: string
  allowedUserCount: number
  currentUserCount: number
  subscriptionId: string
  onLicenseStatusChange: () => void
  onCancel: () => void
  status: string
  license: License
}

export function Subscription(props: SubscriptionProps) {
  const {
    name,
    allowedUserCount,
    currentUserCount,
    status,
    onLicenseStatusChange,
    onCancel,
    license,
  } = props
  const classes = useStyles()

  const usedPercentage = (currentUserCount / allowedUserCount) * 100
  const isPayPalSubscription = license.subscriptionStatus

  return isPayPalSubscription ? (
    <ExistingItem
      key={name}
      name={name}
      id={''}
      onEdit={
        license.subscriptionStatus !== 'CANCELLED'
          ? () => onLicenseStatusChange()
          : undefined
      }
      onDelete={
        license.subscriptionStatus !== 'CANCELLED'
          ? () => onCancel()
          : undefined
      }
      editButtonName={
        license.subscriptionStatus === 'SUSPENDED' ? 'Reactivate' : 'Suspend'
      }
      deleteButtonName={'Cancel'}
      status={
        license.subscriptionStatus === 'SUSPENDED' ||
        license.subscriptionStatus === 'CANCELLED'
          ? `${status} (Expires: ${license.subscriptionEndDate})`
          : status
      }
    >
      <div className={classes.text}>
        <Typography variant='subtitle2' className={classes.progressTitle}>
          {currentUserCount} / {allowedUserCount} users
        </Typography>

        <div className='progressBar'>
          <LinearProgress
            style={{
              display: 'flex',
              width: '200px',
              height: '10px',
            }}
            variant='determinate'
            value={usedPercentage}
            color='secondary'
          />
        </div>
      </div>
    </ExistingItem>
  ) : (
    <ExistingItem
      key={name}
      name={name}
      id={''}
      deleteButtonName={'Cancel'}
      status={
        license.subscriptionStatus === 'SUSPENDED' ||
        license.subscriptionStatus === 'CANCELLED'
          ? `${status} (Expires: ${license.subscriptionEndDate})`
          : status
      }
    >
      <div className={classes.text}>
        <Typography variant='subtitle2' className={classes.progressTitle}>
          {currentUserCount} users
        </Typography>
      </div>
    </ExistingItem>
  )
}
