import {useEffect, useState} from 'react'
import {TabLayout, TabLayoutModal} from '../../shared'
import {AddAppRegistrationForm} from './add-app-registration-form'
import {ExistingAppRegistration} from './existing-app-registration'
import {NoResultsText} from '../../../../components'
import {useGetAppRegs} from '../../../../hooks'
import {ExistingItemSkeleton} from '../../shared/existing-items/existing-item-skeleton'

export function AppRegistrationConfig() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {refetch: getAppRegs} = useGetAppRegs()

  function handleClose() {
    setIsModalOpen(false)
  }

  function handleSave() {
    getAppRegs()
  }

  return (
    <>
      <TabLayout
        tabTitle='App Registrations'
        primaryButton={{
          buttonText: 'Create new',
          onClick: () => setIsModalOpen(true),
        }}
      >
        <div>
          <AppRegDisplay />
        </div>
      </TabLayout>
      <TabLayoutModal
        title='Add App Registration'
        isOpen={isModalOpen}
        onClose={handleClose}
      >
        <AddAppRegistrationForm onClose={handleClose} onSave={handleSave} />
      </TabLayoutModal>
    </>
  )
}

function AppRegDisplay() {
  const {data: appRegs} = useGetAppRegs()

  const [skeletonNumber, setSkeletonNumber] = useState(5)

  useEffect(
    () => {
      //Check if we have the number of reports in local storage
      const appRegsInLocalStorage = localStorage.getItem('appRegs')
      if (appRegsInLocalStorage) {
        setSkeletonNumber(parseInt(appRegsInLocalStorage))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!appRegs) return
    localStorage.setItem('appRegs', JSON.stringify(appRegs.length))
  }, [appRegs])

  if (!appRegs) return <ExistingItemSkeleton numberOfItems={skeletonNumber} />

  if (!appRegs.length)
    return (
      <NoResultsText>You haven't created any app registrations!</NoResultsText>
    )

  return (
    <>
      {appRegs.map(appReg => (
        <ExistingAppRegistration key={appReg.id} appRegistration={appReg} />
      ))}
    </>
  )
}
