import queryString from 'query-string'

const baseUrl = '/api' // 'http://127.0.0.1:7071/api'

console.info('baseUrl', baseUrl)

export const endpoints = {
  reports: `${baseUrl}/reports`,
  userReports: `${baseUrl}/user-reports`,
  appRegistration: `${baseUrl}/app-registration`,
  embedAccess: `${baseUrl}/embed-access`,
  users: `${baseUrl}/users`,
  testConnection: `${baseUrl}/test-report-connection`,
  userInfo: `${baseUrl}/user-info`,
  exportUsers: `${baseUrl}/export-users`,
  license: `${baseUrl}/license`,
  theme: `${baseUrl}/theme`,
  reportViewers: `${baseUrl}/report-viewers`,
  reportDefault: `${baseUrl}/report-default`,
  appRegistrationReports: `${baseUrl}/app-registration-reports`,
  paypalSubscriptionPlan: `${baseUrl}/paypal-subscription-plan`,
  paypalLicense: `${baseUrl}/paypal-license`,
  groups: `${baseUrl}/groups`,
  groupUsers: `${baseUrl}/group-users`,
  userGroups: `${baseUrl}/user-groups`,
  appSettings: `${baseUrl}/app-settings`,
  
}

export function getUrl(
  endpoint: string,
  query: {[key: string]: string | string[]}
) {
  return endpoint + '?' + queryString.stringify(query, {arrayFormat: 'comma'})
}
