import {FC, useEffect, useState} from 'react'
import {useGetGroups} from '../../../../hooks'
import {Group} from '../../../../models'
import {TabLayout, TabLayoutModal} from '../../shared'
import {GroupForm} from './group-form'
import {ExistingGroup} from './existing-group'
import {v4 as uuidv4} from 'uuid'
import {ExistingItemSkeleton} from '../../shared/existing-items/existing-item-skeleton'

export const GroupsConfig: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <TabLayout
        tabTitle='Groups'
        primaryButton={{
          buttonText: 'Create group',
          onClick: () => setIsModalOpen(true),
        }}
      >
        <GroupsDisplay />
      </TabLayout>
      <TabLayoutModal
        title='Add New Group'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <GroupForm onClose={() => setIsModalOpen(false)} editMode={false} />
      </TabLayoutModal>
    </>
  )
}

export const GroupsDisplay: FC = () => {
  const {data: groups, isLoading: groupsLoading} = useGetGroups()
  const [skeletonNumber, setSkeletonNumber] = useState(10)

  useEffect(() => {
    //Check if we have the number of groups in local storage
    const groupsInLocalStorage = localStorage.getItem('groups')
    if (groupsInLocalStorage) {
      setSkeletonNumber(parseInt(groupsInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (groupsLoading || !groups) return
    localStorage.setItem('groups', JSON.stringify(groups.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsLoading])

  if (groupsLoading) {
    return <ExistingItemSkeleton numberOfItems={skeletonNumber} />
  }

  return (
    <div>
      {groups.map((group: Group) => (
        <ExistingGroup key={`${group.id}_${uuidv4()}`} group={group} />
      ))}
    </div>
  )
}
