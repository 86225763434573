import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {License, User} from '../../models'
import {licenseAtom} from '../../state'
import {getLicenseQueryKey, getOtherUsersOnLicenseKey} from '../queries'

export type DeleteUserPayload = {userIds: string[]; licenseId: string}

type DeleteUserContext = {
  previousUsers: User[]
}

export function useDeleteUsers() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation(
    (payload: DeleteUserPayload) =>
      userApi.deleteUsers(payload.userIds, payload.licenseId),
    {
      async onMutate(
        deletedUsers: DeleteUserPayload
      ): Promise<DeleteUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnLicenseKey, licenseId])

        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnLicenseKey,
          licenseId,
        ])

        //console.log('previousUsers', previousUsers)

        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          (old: User[]) =>
            old.filter(user => !deletedUsers.userIds.includes(user.email))
        )

        queryClient.setQueryData(
          [getLicenseQueryKey, licenseId],
          (old: License) => ({
            ...old,
            currentUserCount:
              old.currentUserCount - deletedUsers.userIds.length,
          })
        )

        return {previousUsers}
      },
      onError(_err, _newUser, context: DeleteUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getLicenseQueryKey, licenseId])
        queryClient.invalidateQueries([getOtherUsersOnLicenseKey, licenseId])
      },
    }
  )
}
