export enum AppFeature {
  PayPal = 'PayPal',
  Groups = 'Groups',
  CustomLogo = 'CustomLogo',
}

export type AppSettings = {
  payPalEnabled: boolean
  groupsEnabled: boolean
  customLogoEnabled: boolean
}
