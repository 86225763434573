import React from 'react'
import cs from 'classnames'
import {Theme, Button, ButtonProps, CircularProgress} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      wrapper: {
        position: 'relative',
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
    }),
  {name: 'loading-button'}
)

export function LoadingButton(props: LoadingButtonProps) {
  const {children, isLoading, className, ...rest} = props
  const classes = useStyles()

  return (
    <div className={cs(classes.wrapper, className)}>
      <Button {...rest} disabled={isLoading}>
        {children}
      </Button>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

export type LoadingButtonProps = ButtonProps & {
  isLoading: boolean
}
