import React, {useRef} from 'react'
import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Theme,
} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {MoreVert as MoreVertIcon} from '@mui/icons-material'

import {ReactStateSetter} from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    more: {
      marginLeft: theme.spacing(4),
    },
  })
)

export function OverflowMenu(props: OverflowMenuProps) {
  const {
    onDelete,
    onEdit,
    isOpen,
    editButtonName,
    deleteButtonName,
    setIsOpen,
  } = props
  const classes = useStyles()
  const anchorRef = useRef<HTMLButtonElement>()

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setIsOpen(false)
    }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(open => !open)
        }}
        className={classes.more}
        color='primary'
        aria-haspopup='true'
        size='large'
        data-testid='open-overflow-menu'
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <MenuList
            autoFocusItem={isOpen}
            id='menu-list-grow'
            onKeyDown={handleListKeyDown}
          >
            {onEdit && (
              <MenuItem
                onClick={() => {
                  setIsOpen(false)
                  onEdit()
                }}
                data-testid='edit'
              >
                {editButtonName || 'Edit'}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                onDelete()
                setIsOpen(false)
              }}
            >
              {deleteButtonName || 'Delete'}
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

type OverflowMenuProps = {
  isOpen: boolean
  setIsOpen: ReactStateSetter<boolean>
  editButtonName?: string
  deleteButtonName?: string
  onDelete: () => void
  onEdit?: () => void
}
