import {styled, Box, Button, Dialog, Theme} from '@mui/material'

export const StyledDialog = styled(Dialog)(({theme}: {theme: Theme}) => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: 8,
  },
}))

export const StyledDialogContainer = styled(Box)({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '200px',
  justifyContent: 'space-between',
})

export const StyledDialogFooter = styled(Box)(({theme}: {theme: Theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  justifyContent: 'flex-end',
  '& > button:not(:last-of-type)': {
    marginRight: theme.spacing(1),
  },
}))

export const StyledDialogButton = styled(Button)(({theme}: {theme: Theme}) => ({
  [theme.breakpoints.up('xs')]: {
    height: '40px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '32px',
  },
}))
