import React from 'react'
import {Theme, Typography, useTheme, Fade} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {
  ErrorOutlineOutlined,
  ReportProblemOutlined,
  CheckCircleOutlined,
} from '@mui/icons-material'

import {ReportConnectionStatusInfo} from '../../../models'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        margin: `${theme.spacing(4)} 0`,
        borderRadius: theme.spacing(1),
      },
      title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      icon: {
        marginRight: theme.spacing(1),
      },
      messageContainer: {
        width: '100%',
        marginLeft: theme.spacing(0.5),
      },
    }),
  {name: 'status-message'}
)

export function StatusMessage(props: StatusMessageProps) {
  const {status, ...rest} = props

  const classes = useStyles()
  const theme = useTheme()

  function getStatusIconColor(): any {
    switch (status.type) {
      case 'success':
        return [CheckCircleOutlined, theme.palette.success.main]
      case 'error':
        return [ErrorOutlineOutlined, theme.palette.error.main]
      case 'warning':
        return [ReportProblemOutlined, theme.palette.warning.main]
    }
  }

  function getReasons(status: ReportConnectionStatusInfo) {
    return (
      status &&
      status.reasons && (
        <ul>
          {status.reasons.map(r => (
            <li>{r}</li>
          ))}
        </ul>
      )
    )
  }
  if (!status) return null

  let [StatusIcon, statusColor] = getStatusIconColor()

  return (
    <Fade in>
      <div
        {...rest}
        className={classes.root}
        style={{backgroundColor: statusColor + '3B'}}
      >
        <div className={classes.title}>
          <StatusIcon className={classes.icon} style={{color: statusColor}} />
          <Typography variant='body2' style={{fontWeight: 700}}>
            {status.title}
          </Typography>
        </div>
        <div className={classes.messageContainer}>
          <Typography variant='body2'>{status.message}</Typography>
        </div>
        {getReasons(status)}
      </div>
    </Fade>
  )
}

type StatusMessageProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  status: ReportConnectionStatusInfo
}
