import {FC} from 'react'
import {CircularProgress, SxProps, Theme} from '@mui/material'
import {StyledOverlayBox, StyledCenteredBox} from './Loader.styles'
import {circularProgressPropsBase} from './constants'

export interface ContentLoaderProps {
  loading: boolean
  sx?: SxProps<Theme>
}

export const ContentLoader: FC<ContentLoaderProps> = (
  props: ContentLoaderProps
) => {
  return (
    <StyledOverlayBox hidden={!props.loading} sx={props.sx}>
      <StyledCenteredBox>
        <CircularProgress {...circularProgressPropsBase} />
      </StyledCenteredBox>
    </StyledOverlayBox>
  )
}
