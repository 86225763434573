import {alpha} from '@material-ui/core'
// import {TrendingFlat} from '@mui/icons-material'
import {Typography, Theme, Button, Stack, useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {Layout} from '../../components'
import {ArrowLongRight} from '../../components/common/arrow-long'

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    // background: 'linear-gradient(0deg, #e6e6e6 0%, #f4f4f4 100%)',
  },
  bgImage: {
    width: '100%',
    position: 'fixed',
  },
  signUpLogin: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  content: {
    width: '80%',
    height: '100vh',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px',
    maxWidth: '1280px',
    margin: 'auto',
    marginTop: theme.spacing(16),
    padding: theme.spacing(4),
  },
  loginButton: {
    height: '56px',

    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff',
    },
  },
  secondaryLoginButton: {
    height: '56px',
    border: 'solid black 2px',
    color: 'black',
    '&:hover': {
      backgroundColor: alpha('#000000', 0.2),
      border: 'solid black 2px',
    },
  },

  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    margin: '0',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '2.5rem',
  },

  logo: {
    width: '180px',
    margin: theme.spacing(1),
  },
}))

export function LandingPage() {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <div className={classes.page}>
      <Layout>
        {/* <AppBar color='transparent'>
        <Toolbar className={classes.toolBar}>
          <div className={classes.logo}>
            <img src={logo} alt='logo' />
          </div>

          <Typography variant='h5'>Reports</Typography>
          <div className={classes.logo} />
        </Toolbar>
      </AppBar> */}

        <Stack sx={{mx: 2.5, maxWidth: '540px'}}>
          <Typography
            sx={{
              fontWeight: 700,
              letterSpacing: '-1.6px',
              lineHeight: isMobile ? '42px' : '48px',
              fontSize: isMobile ? '38px' : '44px',
              width: isMobile ? '240px' : '540px',
              mb: 3,
              mt: isMobile ? 3 : 9,
            }}
            variant='h3'
            textAlign='left'
          >
            Welcome to Analytics 360
          </Typography>
          <Typography textAlign='left'>
            This application contains UiPath reports which can be accessed by
            authorized users only. If you are a UiPath employee, please use the
            Employee Sign In option.
          </Typography>
          <Stack
            sx={{mt: 3}}
            direction={isMobile ? 'column' : 'row'}
            gap={3}
            width='100%'
            alignContent='center'
            justifyContent='center'
          >
            <Button
              disableElevation
              sx={{
                borderRadius: 0,
                fontSize: '1rem',
                display: 'flex',
                px: 3,
                justifyContent: 'space-between',
              }}
              variant='contained'
              className={classes.loginButton}
              endIcon={<ArrowLongRight />}
              href={`/.auth/login/sf`}
            >
              Partner Sign In
            </Button>
            <Button
              variant='outlined'
              sx={{
                borderRadius: 0,
                fontSize: '1rem',
                display: 'flex',
                px: 3,
                justifyContent: 'space-between',
              }}
              endIcon={<ArrowLongRight />}
              className={classes.secondaryLoginButton}
              href={`/.auth/login/aad`}
            >
              UiPath Employee Sign in
            </Button>
          </Stack>
        </Stack>
      </Layout>
      {/* <img src={splashImg} className={classes.bgImage} alt='background' /> */}
    </div>
  )
}
