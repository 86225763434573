import {useEffect} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {
  useGetLicense,
  useGetClientPrincipal,
  useCurrentUserInfo,
  useGetAppSettings,
} from './hooks'
import {AdminPage, LandingPage, ReportsPage, UserNotInvitedPage} from './pages'
import {FullPageLoader} from './components'
import {useRecoilState} from 'recoil'
import {licenseAtom} from './state'
import {UserFullName, getLicenseState} from './models'
import {useUpdateUserName} from './hooks/mutations/useUpdateUserName'
import {useLicenseRouting} from './useLicenseRouting'
// import {useLicenseRouting} from './useLicenseRouting'

export const routes = {
  reports: {
    index: '/reports',
    report: ({id, title}: {id: string; title: string}) =>
      `/reports/${id}/${title}`,
  },
  admin: {
    index: '/admin',
    reports: '/admin/reports',
    users: '/admin/users',
    groups: '/admin/groups',
    appReg: '/admin/app-registrations',
    appearance: '/admin/appearance',
    subscription: '/admin/subscription',
  },
}

export function Routes() {
  const {data: clientPrincipal, isFetched: isClientFetched} =
    useGetClientPrincipal()
  const {isFetched: appSettingsFetched} = useGetAppSettings()
  const {data: userInfo, isFetched: userInfoFetched} = useCurrentUserInfo()
  const {data: license, isFetched: isLicenseFetched} = useGetLicense()
  const {mutate: updateUserName} = useUpdateUserName()
  const [currentLicense, setCurrentLicense] = useRecoilState(licenseAtom)

  useLicenseRouting()

  useEffect(() => {
    if (userInfoFetched && userInfo && userInfo.licenses?.[0]) {
      // Set the Current license if it does not exist or it is stale (i.e. not part of User Info Licenses)
      const hasCurrentLicense = userInfo.licenses.find(
        license => license.id === currentLicense
      )
      if (!currentLicense || !hasCurrentLicense) {
        const defaultLicense =
          userInfo.licenses.find(license => license.default) ||
          userInfo.licenses[0]
        setCurrentLicense(defaultLicense.id)
      }

      // For New Users, update UserFullName based upon claims
      if (userInfo.newUser) {
        const name = clientPrincipal.claims.filter(c => c.typ === 'name')[0].val
        const userName: UserFullName = {
          firstName: name.split(' ')[0],
          lastName: name.split(' ')[1],
        }

        updateUserName({userName})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoFetched])

  // User is not authenticated
  const userEmail = clientPrincipal?.userDetails
  if (userInfoFetched && !userEmail) {
    return <GoToLandingPage />
  }

  // User has no Licenses
  if (
    (userInfoFetched && !userInfo?.user) ||
    (userInfoFetched && !userInfo.licenses.length)
  ) {
    return <UserNotInvitedPage />
  }

  // Page is Loading
  if (
    !appSettingsFetched ||
    !isLicenseFetched ||
    !isClientFetched ||
    !userInfoFetched
  ) {
    return <FullPageLoader />
  }

  // Cancelled + Expired License
  // Owner should be redirected to Pricing
  const licenseState = getLicenseState(license)
  if (
    isLicenseFetched &&
    licenseState.status === 'CANCELLED' &&
    licenseState.expired &&
    license.isOwner
  ) {
    return <UserNotInvitedPage />
  }

  return (
    <Switch>
      <Route path={routes.admin.index}>
        {license.isAdmin && <AdminPage />}
        {!license.isAdmin && <Redirect to={routes.reports.index} />}
      </Route>
      <Route path={routes.reports.index}>
        <ReportsPage />
      </Route>
      <Route>
        <Redirect to={routes.reports.index} />
      </Route>
    </Switch>
  )
}

export function GoToLandingPage() {
  return (
    <Switch>
      <Route path='/'>
        <LandingPage />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}
