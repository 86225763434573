import {Link} from 'react-router-dom'
import cs from 'classnames'
import {
  Card,
  CardContent,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {useRoutes} from '../../../hooks'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'auto',
      width: '100%',
      position: 'relative',
      textDecoration: 'none',
      '&:hover': {
        boxShadow: theme.shadows[4],

        backgroundColor: theme.palette.background.paper + ' !important',
      },
    },
    tile: {
      minWidth: '300px',
      width: 'calc((100% - 48px) / 3)',
      minHeight: '120px',
    },
    listItem: {
      width: '100%',

      // margin: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: '100%',
      paddingBottom: theme.spacing(1) + '!important',
      paddingRight: theme.spacing(1),
      '&:hover, MuiButtonBase-root': {
        background: 'none',
      },
    },
    bottomContent: {
      display: 'flex',
      flexGrow: 1,
    },
    description: {
      flexGrow: 1,
    },
    openBtn: {
      alignSelf: 'flex-end',
    },
  }),
  {name: 'ReportTile'}
)

export function ReportTile(props: ReportTileProps) {
  const {title, description, id} = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const classes = useStyles()
  const routes = useRoutes()

  return (
    <Card
      square
      elevation={0}
      className={cs(classes.root, {
        [classes.tile]: !isMobile,
        [classes.listItem]: isMobile,
      })}
      component={Link}
      to={routes.reports.report({id, title})}
    >
      <CardContent>
        <Typography gutterBottom variant='h6' component='h2'>
          {title}
        </Typography>
        <div className={classes.bottomContent}>
          <Typography
            variant='body2'
            color='textSecondary'
            component='p'
            className={classes.description}
          >
            {description}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

type ReportTileProps = {
  title: string
  description: string
  id: string
}
