//Functions for sorting and searching users + reports

export function filterIt(arr, searchKey) {
  if (searchKey && searchKey !== '') {
    searchKey = searchKey.toLowerCase()
    //console.log(searchKey)
    ////console.log("Arr before filter: ", arr);
    arr = arr.filter(obj => checkObject(obj, searchKey))
    ////console.log("Arr after filter: ", arr);
    return arr
  }
  return arr
}

export function checkObject(obj, searchKey) {
  let saveVal = false
  Object.values(obj).forEach(value => {
    if (value && value.toString().toLowerCase().includes(searchKey)) {
      saveVal = true
    }
  })

  return saveVal
}

export function compareString(a: string, b: string) {
  if (!a || !b) {
    return 0
  }
  let aVal: string | number = a.toLowerCase()
  let bVal: string | number = b.toLowerCase()

  const parsedDateA = Date.parse(a)
  const parsedDateB = Date.parse(b)
  if (!isNaN(parsedDateA) && !isNaN(parsedDateB)) {
    aVal = parsedDateA
    bVal = parsedDateB
  }

  if (aVal < bVal) {
    return -1
  } else if (bVal > aVal) {
    return 1
  }
  return 0
}
