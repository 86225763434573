import {useState} from 'react'
import AddIcon from '@mui/icons-material/Add'
import {TabLayoutModal} from '../../shared'
import {ImageEditor} from './image-edit'
import {Button} from '@mui/material'

type ImageSelectorProps = {
  setLogo: (logo: string) => void
  logo: string
  primaryColor: string
  secondaryColor: string
}

export function ImageSelector(props: ImageSelectorProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {logo, setLogo, primaryColor, secondaryColor} = props

  const handleLogoChange = (logo: HTMLCanvasElement) => {
    setLogo(logo.toDataURL('image/png', 1.0))
    setIsModalOpen(false)
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {logo && (
            <Button
              style={{color: secondaryColor, marginTop: '1rem'}}
              onClick={() => {
                setLogo(null)
              }}
            >
              Remove
            </Button>
          )}
          <Button
            style={{color: secondaryColor, marginTop: '1rem'}}
            onClick={() => setIsModalOpen(true)}
          >
            <AddIcon />
            Add Logo
          </Button>
        </div>
      </div>

      <TabLayoutModal
        title='Add New Logo'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ImageEditor
          handleLogoChange={handleLogoChange}
          backgroundColor={primaryColor}
        />
      </TabLayoutModal>
    </>
  )
}
