import {ThemeOptions} from '@mui/material'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#fa4617',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#F2F2F2',
    },
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1720,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    button: {
      textTransform: 'capitalize',
    },
  },
}
