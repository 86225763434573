import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {AllReports, ApiReport, GetAllReportsForUserResponse} from '../../models'
import {licenseAtom} from '../../state'
import {getReportsByUserKey, getReportsKey} from '../queries'

type AddReportsToUserPayload = {
  reportIds: string[]
  viewerId: string
}

/** Add viewer to a PBI reports */
export function useAddReportsToUser() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportsToUserPayload) => reportApi.addViewerToReports(payload.reportIds, payload.viewerId, licenseId),
    {
      async onMutate(payload: AddReportsToUserPayload): Promise<GetAllReportsForUserResponse> {
        await queryClient.cancelQueries([getReportsByUserKey, payload.viewerId])
        await queryClient.cancelQueries([getReportsKey, licenseId])

        let allReports: AllReports = queryClient.getQueryData([getReportsKey, licenseId])

        if (!allReports) {
          allReports = await queryClient.fetchQuery([getReportsKey, licenseId], () => reportApi.getReports(licenseId))
        }

        //console.log('allReports', allReports)

        const previousReports: GetAllReportsForUserResponse = queryClient.getQueryData([
          getReportsByUserKey,
          payload.viewerId,
        ])

        queryClient.setQueryData([getReportsByUserKey, payload.viewerId], (old: GetAllReportsForUserResponse) => {
          const reportsBeingAdded = allReports.licenseReports.filter((r: ApiReport) => payload.reportIds.includes(r.id))
          const userReports = [...old.reportsFromUser, ...reportsBeingAdded]

          return {
            reportsFromGroups: old.reportsFromGroups,
            reportsFromUser: userReports,
          }
        })

        return previousReports
      },
      onError(err: Error, payload: AddReportsToUserPayload, previousReports: GetAllReportsForUserResponse) {
        queryClient.setQueryData([getReportsByUserKey, payload.viewerId], previousReports)
      },
      // Always refetch after error or success:
      onSettled() {
        queryClient.invalidateQueries(getReportsByUserKey)
        queryClient.invalidateQueries(getReportsKey)
      },
    }
  )
}
