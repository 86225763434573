import {Group} from './group'
import {User} from './user'

export type ReportData = {
  id?: string
  name?: string
  description?: string
  url?: string
  default?: boolean
}

export type RlsValue = {
  roles: string[]
  username: 'email'
}

export type ReportToCreate = ReportData & {
  pbiReportId?: string
  pbiWorkspaceId?: string
  appRegistrationNodeId: string
  appRegistrationName: string
  licenseNodeId: string
  viewers?: string[]
  rls?: RlsValue
}

export type AllReports = {
  ownedReports: ApiReport[]
  sharedReports: ApiReport[]
  defaultReports: ApiReport[]
  licenseReports: ApiReport[]
}

export type ApiReport = ReportData & {
  owner: string
  pbiWorkspaceId: string
  pbiReportId: string
  appRegistrationNodeId: string
  appRegistrationName: string
  rls?: RlsValue
  default?: boolean
}

export type ApiGroupReport = ApiReport & {
  groupId: string
  groupName: string
}

export type ReportsFromUser = {
  reportsFromGroups: ApiGroupReport[]
  reportsFromUser: ApiReport[]
}

export type ReportConnectionStatusInfo = {
  type: string
  title: string
  message?: string
  reasons?: string[]
}

export type GetReportViewersResponse = {
  users: User[]
  groups: Group[]
}

export type GetAllReportsForUserResponse = {
  reportsFromGroups: ApiGroupReport[]
  reportsFromUser: ApiReport[]
}

export enum ReportConnectionStatus {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  RowLevelSecurity = 999,
}
