import {Redirect, Route, Switch} from 'react-router-dom'
import {createStyles, makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'
import {useGetAppSettings, useRoutes, useGetLicense} from '../../hooks'
import {
  AppRegistrationConfig,
  ReportsConfig,
  UsersConfig,
  AppearanceConfig,
  GroupsConfig,
} from './tabs'
import {Layout} from '../../components/layout'
import {SubscriptionPage} from './tabs/subscription'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adminRoot: {
      alignItems: 'flex-start',
      padding: theme.spacing(3),

      margin: 'auto',
      maxWidth: '1600px',
    },
    paper: {
      display: 'flex',
      padding: theme.spacing(1),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      alignContent: 'center',
      height: 'auto',
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(2),
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    divider: {
      width: '1px',
      backgroundColor: theme.palette.divider,
    },
  })
)

export function AdminPage() {
  const classes = useStyles()

  return (
    <Layout>
      <div className={classes.adminRoot}>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <AdminRoutes />
          </div>
        </div>
      </div>
    </Layout>
  )
}

function AdminRoutes() {
  const routes = useRoutes()
  const {data: appSettings} = useGetAppSettings()
  const {data: license} = useGetLicense()
  const showSubscriptions =
    appSettings?.payPalEnabled && (license?.isOwner || license.isAdmin)
  return (
    <Switch>
      <Route path={routes.admin.appReg}>
        <AppRegistrationConfig />
      </Route>
      <Route path={routes.admin.reports}>
        <ReportsConfig />
      </Route>
      <Route path={routes.admin.users}>
        <UsersConfig />
      </Route>
      {appSettings?.groupsEnabled && (
        <Route path={routes.admin.groups}>
          <GroupsConfig />
        </Route>
      )}
      <Route path={routes.admin.appearance}>
        <AppearanceConfig />
      </Route>

      <Route path={routes.admin.subscription}>
        {showSubscriptions ? (
          <SubscriptionPage />
        ) : (
          <Redirect to={routes.reports.index} />
        )}
      </Route>
      <Route>
        <Redirect to={routes.admin.appReg} />
      </Route>
    </Switch>
  )
}
