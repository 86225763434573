import {
  GetUserInfoResponse,
  Group,
  NewUser,
  User,
  UserFullName,
  UserWithGroups,
} from '../models'
import {getAxios, endpoints, getUrl} from './util'

export const userApi = {
  async createUser(users: NewUser[], licenseId: string, reportIds: string[]) {
    let axios = await getAxios()
    let data = {
      users,
      licenseId,
      reportIds,
    }

    try {
      await axios({
        method: 'put',
        url: endpoints.users,
        data: data,
      })
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async updateUserName(userName: UserFullName): Promise<User> {
    let axios = await getAxios()
    let data = {
      userName,
    }
    try {
      let response = await axios({
        method: 'post',
        url: endpoints.userInfo,
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async updateUsers(users: NewUser[], licenseId: string): Promise<User[]> {
    let axios = await getAxios()

    // //console.log('UPDATE USERS HAS BEEN CALLED')

    let data = {
      users,
      licenseId,
    }

    // //console.log('data', data)

    try {
      let response = await axios({
        method: 'post',
        url: endpoints.users,
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async updateUser(user: NewUser, licenseId: string): Promise<User> {
    let axios = await getAxios()

    let data = {
      user,
      licenseId,
    }

    try {
      let response = await axios({
        method: 'patch',
        url: endpoints.users,
        data: data,
      })
      return response.data
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async deleteUsers(userIds: string[], licenseId: string) {
    let axios = await getAxios()

    try {
      await axios({
        method: 'delete',
        url: endpoints.users,
        data: {userIds, licenseId},
      })
    } catch (e) {
      if (e.response) {
        e.message = e.response.data.errorMessage
      }
      throw e
    }
  },

  async getLicenseUsers(licenseId: string) {
    let axios = await getAxios()
    let url = getUrl(endpoints.users, {licenseNodeId: licenseId})
    let response
    try {
      response = await axios({
        method: 'get',
        url,
      })
    } catch (e) {
      console.log('Error: ', e)
      return []
    }

    return response.data.payload as User[]
  },

  async getCurrentUserInfo(): Promise<GetUserInfoResponse> {
    let axios = await getAxios()

    try {
      let response = await axios.get<GetUserInfoResponse>(endpoints.userInfo)

      return response.data
    } catch (e) {
      return null
    }
  },

  async updateDefaultLicenseId(licenseId: string): Promise<User> {
    let axios = await getAxios()

    try {
      const data = {licenseId}
      const response = await axios({
        method: 'put',
        url: endpoints.userInfo,
        data,
      })

      return response.data
    } catch (e) {
      return null
    }
  },

  async getUserGroups(userId: string, licenseId: string): Promise<Group[]> {
    let axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.userGroups, {userId, licenseId}),
    })

    return response.data.userGroups
  },

  async getExportUsers(
    licenseId: string,
    includeReports: boolean,
    userIds: string[]
  ): Promise<UserWithGroups[]> {
    let axios = await getAxios()

    const data = {
      licenseId,
      includeReports,
      userIds,
    }

    const response = await axios({
      method: 'post',
      url: endpoints.exportUsers,
      data,
    })
    const exportUsersData = response.data.export

    const formattedUsers = exportUsersData.map((user: UserWithGroups) => {
      return {
        ...user,
        groups: user.groups?.map((group: any) => group.name) || [],
        reports: user.reports?.map((report: any) => report.name) || [],
      }
    })
    return formattedUsers
  },
}
