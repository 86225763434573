import React, {useState} from 'react'
import {Collapse, Theme} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

import {
  ApiReport,
  ReportConnectionStatus,
  ReportConnectionStatusInfo,
  RlsValue,
} from '../../../../models'
import {LoadingButton} from '../../../../components'
import {Form, FormField, StatusMessage} from '../../shared'
import {connectionStatusInfo} from './test-connection-responses'
import {RlsFields} from './rls-fields'
import {
  useCreateReport,
  useUpdateReport,
  useGetAppRegs,
  useGetLicense,
  useTestReportConnection,
} from '../../../../hooks'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      statusMessage: {
        width: '100%',
      },
      rlsSection: {
        width: '100%',
      },
    }),
  {name: 'add-report-form'}
)

export function AddReportForm(props: AddReportContentProps) {
  const {onClose, onSave, isEdit, reportToEdit} = props
  const classes = useStyles()

  const [reportUrl, setReportUrl] = useState(reportToEdit?.url ?? '')
  const [appRegistrationId, setAppRegistrationId] = useState(
    reportToEdit?.appRegistrationNodeId ?? ''
  )
  const [pbiWorkspaceId, setPbiWorkspaceId] = useState(
    reportToEdit?.pbiWorkspaceId ?? ''
  )
  const [pbiReportId, setPbiReportId] = useState(
    reportToEdit?.pbiReportId ?? ''
  )
  const [appRegistrationName, setAppRegistrationName] = useState(
    reportToEdit?.appRegistrationName ?? ''
  )
  const [name, setName] = useState(reportToEdit?.name ?? '')
  const [description, setDescription] = useState(
    reportToEdit?.description ?? ''
  )
  const [isRlsRequired, setIsRlsRequired] = useState(
    reportToEdit?.rls?.username ? true : false
  )

  const [status, setStatus] = useState<ReportConnectionStatusInfo>(null)
  const [rlsValues, setRlsValues] = useState<RlsValue>(
    reportToEdit?.rls ?? {roles: [''], username: null}
  )

  const {data: existingAppRegistrations} = useGetAppRegs()
  const {data: license} = useGetLicense()
  const {mutateAsync: testReportConnection, isLoading: isTestingConnection} =
    useTestReportConnection()
  const {mutateAsync: createReport, isLoading: isSaving} = useCreateReport()
  const {mutateAsync: updateReport, isLoading: isUpdating} = useUpdateReport()

  async function testConnection() {
    let status = await testReportConnection({
      pbiWorkspaceId,
      pbiReportId,
      appRegistrationNodeId: appRegistrationId,
    })

    setStatus(connectionStatusInfo[status])
    setIsRlsRequired(status === ReportConnectionStatus.RowLevelSecurity)
  }

  async function handleSave() {
    let reportData = {
      id: reportToEdit?.id,
      name,
      pbiReportId,
      pbiWorkspaceId,
      appRegistrationNodeId: appRegistrationId,
      appRegistrationName,
      licenseNodeId: license.id,
      url: reportUrl,
      description,
      rls: rlsValues.username ? rlsValues : null,
    }

    if (!isEdit) {
      await createReport(reportData)
    } else {
      //update report

      await updateReport(reportData)
    }
    onSave()
    onClose()
  }

  function handleReportUrlTextChange(url: string) {
    // https://app.powerbi.com/groups/WORKSPACE_ID/reports/REPORT_ID/ReportSection
    // https://app.powerbi.com/groups/WORKSPACE_ID/reports/REPORT_ID
    let matches = url.match(/.+\/groups\/(.+)\/reports\/(.+)/)
    setStatus(undefined)

    setReportUrl(url)
    if (!matches) {
      // TODO: Display some error
      console.error('You put a bad report url', url)
      return
    }

    setPbiWorkspaceId(matches[1])
    // The regex will grab everything after /reports/ so if the url continues after
    // the report id, it will also be in match for ReportId. We only want to grab the
    // immediate section after reports
    setPbiReportId(matches[2].split('/')[0])
  }

  return (
    <Form
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isUpdating}
    >
      <FormField
        label='Name'
        value={name}
        helperText=''
        onTextChange={setName}
      />
      <FormField
        label='Report URL'
        value={reportUrl}
        helperText=''
        onTextChange={handleReportUrlTextChange}
      />
      <FormField
        label='Description'
        value={description}
        helperText=''
        onTextChange={setDescription}
      />

      <FormField
        label='Select App Registration'
        value={appRegistrationName}
        onTextChange={value => {
          setStatus(null)
          setIsRlsRequired(false)
          let selectedAppRegistration =
            existingAppRegistrations &&
            existingAppRegistrations.find(o => o.name === value)

          if (!selectedAppRegistration) return

          setAppRegistrationId(selectedAppRegistration.id)
          setAppRegistrationName(value)
        }}
        selectOptions={
          existingAppRegistrations && existingAppRegistrations.map(a => a.name)
        }
        helperText='The app registration that has access to this report'
      />

      <LoadingButton
        color='secondary'
        onClick={testConnection}
        disabled={appRegistrationId === ''}
        isLoading={isTestingConnection}
      >
        Test Connection
      </LoadingButton>

      <Collapse className={classes.statusMessage} in={!!status}>
        <StatusMessage status={status} />
      </Collapse>
      <Collapse className={classes.rlsSection} in={isRlsRequired}>
        <RlsFields
          roles={rlsValues.roles}
          username={rlsValues.username}
          onRolesChange={r => setRlsValues({username: 'email', roles: r})}
        ></RlsFields>
      </Collapse>
    </Form>
  )
}

export type AddReportContentProps = {
  onClose: () => void
  onSave: () => void
  isEdit?: boolean
  reportToEdit?: ApiReport
}
