import cs from 'classnames'
import {createStyles, makeStyles} from '@mui/styles'
import {
  LogoutOutlined,
  MailOutlineRounded,
  Menu as MenuIcon,
} from '@mui/icons-material'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Theme,
  Box,
  Tooltip,
  Fade,
} from '@mui/material'
import logo from '../../assets/UiPath.svg'
import {ArrowLongRight} from '../common/arrow-long'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbarShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
          height: '40px !important',
          paddingLeft: `${theme.spacing(2)} !important`,
          paddingRight: `${theme.spacing(2)} !important`,
        },
      },

      shrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
        },
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        color: '#000',
        zIndex: 1301,
        borderBottom: '1px solid #e0e0e0',
      },
      toolbar: {
        minHeight: '40px',
        width: '100%',
        height: '64px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      buttonShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          width: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
          width: '40px',
        },
      },
      menuButton: {
        '&:hover, &.Mui-focusVisible': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      rightSide: {
        display: 'flex',
        alignItems: 'center',
      },
      logo: {
        marginLeft: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
      titleShrink: {
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          marginLeft: theme.spacing(1),
        },
      },
    }),
  {name: 'main-app-bar'}
)

export function MainAppBar(props: MainAppBarProps) {
  const {
    toggleOpen,
    title,
    showButtons,
    showBackButton,
    fullWidth,
    pageWidth,
    hideLogo,
  } = props
  const classes = useStyles()

  let BackButton = !toggleOpen && (
    <IconButton
      className={cs(classes.menuButton, {[classes.buttonShrink]: fullWidth})}
      component='a'
      color='inherit'
      aria-label='menu'
      href='/'
      size='large'
    >
      <ArrowLongRight style={{transform: 'rotate(180deg)'}} />
    </IconButton>
  )
  let MenuButton = (
    <IconButton
      className={cs(classes.menuButton, {[classes.buttonShrink]: fullWidth})}
      color='inherit'
      aria-label='menu'
      onClick={toggleOpen}
      size='large'
    >
      <MenuIcon />
    </IconButton>
  )

  return (
    <AppBar
      elevation={0}
      position='fixed'
      className={cs(classes.appBar, {[classes.shrink]: fullWidth})}
    >
      <Toolbar
        className={cs(classes.toolbar, {[classes.toolbarShrink]: fullWidth})}
        sx={{maxWidth: fullWidth ? '100%' : `${pageWidth}px`}}
      >
        <Box className={classes.leftSide}>
          {toggleOpen && MenuButton}
          {showBackButton && BackButton}
          {!hideLogo && (
            <Box className={classes.logo}>
              <img src={logo} alt='logo' />
            </Box>
          )}
          <Typography
            variant='h6'
            className={cs({[classes.titleShrink]: fullWidth})}
          >
            {title}
          </Typography>
        </Box>

        <Box className={classes.rightSide}>
          {showButtons && <ToolbarItems />}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
function ToolbarItems() {
  const classes = useStyles()
  return (
    <Stack direction='row' gap={2}>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: 0,
              backgroundColor: '#414244',
              borderColor: '#414244',
              textTransform: 'uppercase',
            },
          },
          arrow: {
            sx: {
              color: '#414244',
            },
          },
        }}
        title='Contact us'
        TransitionComponent={Fade}
      >
        <IconButton
          edge='start'
          className={cs(classes.menuButton)}
          color='inherit'
          aria-label='logout'
          LinkComponent={'a'}
          href='mailto:support.analytics360@uipath.com'
          size='large'
        >
          <MailOutlineRounded />
        </IconButton>
      </Tooltip>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: 0,
              backgroundColor: '#414244',
              textTransform: 'uppercase',
            },
          },
          arrow: {
            sx: {
              color: '#414244',
            },
          },
        }}
        arrow
        title='Log out'
        TransitionComponent={Fade}
      >
        <IconButton
          edge='start'
          className={cs(classes.menuButton)}
          color='inherit'
          aria-label='logout'
          LinkComponent={'a'}
          href='/.auth/logout'
          size='large'
        >
          <LogoutOutlined />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export type MainAppBarProps = {
  toggleOpen?: () => void
  title: string
  fullWidth?: boolean
  showButtons?: boolean
  showBackButton?: boolean
  hideLogo?: boolean
  pageWidth: number
}
