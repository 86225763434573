import {CircularProgress, Theme} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {LinkedItemChip} from '../../shared'
import {AppRegistrationState} from '../../../../models'
import {NoResultsText} from '../../../../components'
import {
  useGetLinkedReportsByAppReg,
  useGetReports,
  useUnlinkReportFromAppReg,
} from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export function LinkedReports(props: LinkedReportsProps) {
  const {appRegistration} = props
  const classes = useStyles()
  const {data: allReports} = useGetReports()
  const reports = allReports?.licenseReports
  const {data: linkedReportIds} = useGetLinkedReportsByAppReg(
    appRegistration.id
  )

  const {mutate: unlinkReportFromAppReg} = useUnlinkReportFromAppReg()

  const linkedReports =
    reports &&
    linkedReportIds &&
    reports.filter(r => linkedReportIds.includes(r.id))

  function handleDelete(linkedReportId: string) {
    unlinkReportFromAppReg({
      appRegId: appRegistration.id,
      reportId: linkedReportId,
    })
  }

  if (!linkedReports) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (!linkedReports.length) {
    return (
      <div>
        <NoResultsText>
          There are no reports currently linked to this app registration
        </NoResultsText>
      </div>
    )
  }

  return (
    <>
      {linkedReports.map(linkedReport => (
        <LinkedItemChip
          key={linkedReport.id}
          label={linkedReport.name}
          onDelete={() => handleDelete(linkedReport.id)}
        />
      ))}
    </>
  )
}

type LinkedReportsProps = {
  appRegistration: AppRegistrationState
}
