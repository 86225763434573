import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {appRegApi} from '../../api-interface'
import {AppRegistrationState, AppRegistration} from '../../models'
import {licenseAtom} from '../../state'
import {getAppRegistrationsKey} from '../queries'

type UpdateAppRegContext = {
  previousAppRegistrations: AppRegistrationState[]
}

export function useUpdateAppReg() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: AppRegistration) => appRegApi.updateAppRegistration(payload),
    {
      async onMutate(payload): Promise<UpdateAppRegContext> {
        await queryClient.cancelQueries([getAppRegistrationsKey, licenseId])

        let previousAppRegistrations: AppRegistrationState[] =
          queryClient.getQueryData([getAppRegistrationsKey, licenseId])

        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          (old: AppRegistrationState[]) => {
            if (!old) return []

            const secretHasBeenUpdated =
              payload.appRegistrationSecret !==
              'somereallylongplaceholdervalueforafakepassword'

            return old.map(appReg => {
              if (appReg.id === payload.id) {
                return {
                  appRegistrationId: payload.appRegistrationId,
                  id: payload.id,
                  tenantId: payload.tenantId,
                  name: payload.name,
                  ...(secretHasBeenUpdated
                    ? {appRegistrationSecret: payload.appRegistrationSecret}
                    : {}),
                }
              }
              return appReg
            })
          }
        )

        return {previousAppRegistrations}
      },
      onError(_err, _updatedUser, context: UpdateAppRegContext) {
        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          context.previousAppRegistrations
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAppRegistrationsKey, licenseId])
      },
    }
  )
}
