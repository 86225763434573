import {useCallback, useEffect, useState} from 'react'
import {Stack, TextField} from '@mui/material'
import {TabLayout, TabLayoutModal} from '../../shared'
import {AddReportForm} from './add-report-form'
import {ExistingReport} from './existing-report'
import {useGetReports} from '../../../../hooks'
import {filterIt, compareString} from '../../../../utils'
//import {onlyUnique} from '../../../reports/util'
import {SortControls, SortDirection} from '../../shared/sort-controls'
import {NoResultsText} from '../../../../components'
import {ExistingItemSkeleton} from '../../shared/existing-items/existing-item-skeleton'

export function ReportsConfig() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {refetch: getReports} = useGetReports()

  function handleSave() {
    setIsModalOpen(false)
    getReports()
  }

  return (
    <>
      <TabLayout
        tabTitle='Reports'
        primaryButton={{
          buttonText: 'Create new',
          onClick: () => setIsModalOpen(true),
        }}
      >
        <ReportDisplay />
      </TabLayout>
      <TabLayoutModal
        title='Add Report'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <AddReportForm
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
        />
      </TabLayoutModal>
    </>
  )
}

function ReportDisplay() {
  const {data: reports, isLoading: reportsLoading} = useGetReports()
  const allReports = reports?.licenseReports || []
  // todo: filter reports for duplicates
  //let uniqueReports = allReports.filter(onlyUnique)

  useEffect(() => {
    if (reports?.licenseReports && reports?.licenseReports.length > 0)
      handleSort()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports])

  // TODO: Consider making a component for the search box...
  const SearchBox = useCallback(() => {
    return (
      <TextField
        id='standard'
        variant='outlined'
        fullWidth
        label='Search'
        onChange={handleSearchTermChange}
        sx={{width: '200px'}}
        size='small'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports])

  const [sortedReports, setSortedReports] = useState(allReports)
  const [sortValue, setSortValue] = useState<string>('name')
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Ascending
  )
  const [skeletonNumber, setSkeletonNumber] = useState(10)

  const handleSearchTermChange = e => {
    let newReports = filterIt(allReports.slice(), e.target.value)
    setSortedReports(newReports)
  }

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem('reports')
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reportsLoading || !allReports) return
    localStorage.setItem('reports', JSON.stringify(allReports.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsLoading])

  if (!reportsLoading && !allReports.length)
    return <NoResultsText>You haven't created any reports!</NoResultsText>

  return (
    <Stack sx={{height: '100%'}} direction='column'>
      {/* Search and sort box */}
      <Stack direction='row' alignItems='center' sx={{mb: 1}} gap={1}>
        <SearchBox />
        <SortControls
          sortValue={sortValue}
          onSortValueChange={sortBy => {
            setSortValue(sortBy)
            handleSort()
          }}
          sortDirection={sortDirection}
          onSortDirectionChange={direction => {
            setSortDirection(direction)
            handleSort()
          }}
          sortColumns={[{label: 'Name', value: 'name'}]}
        />
      </Stack>

      {/* Reports */}
      {reportsLoading && (
        <ExistingItemSkeleton numberOfItems={skeletonNumber} />
      )}
      {sortedReports && sortedReports.length > 0 && (
        <>
          {sortedReports.map(report => (
            <ExistingReport
              key={report.id}
              report={report}
              isDisabled={false}
            />
          ))}
        </>
      )}
    </Stack>
  )

  function handleSortReports(direction: string) {
    if (allReports) {
      let reports = [...allReports]
      if (direction === 'asc') {
        return reports.sort((a, b) => compareString(a[sortValue], b[sortValue]))
      } else if (direction === 'dsc') {
        return reports.sort((b, a) => compareString(a[sortValue], b[sortValue]))
      }
    }
  }

  function handleSort() {
    let sorted = handleSortReports(sortDirection)
    if (sorted) setSortedReports([...sorted])
  }
}
