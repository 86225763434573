import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

export function ArrowLongRight(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16' height='16' width='16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1331 7.37L10.9488 5.16111C10.6886 4.898 10.691 4.47379 10.9541 4.2136C11.2172 3.95342 11.6414 3.95579 11.9016 4.2189L15.1819 7.53608C15.2372 7.58543 15.2836 7.64356 15.3197 7.70734C15.346 7.75314 15.3663 7.80135 15.3809 7.85091C15.4433 8.06056 15.4064 8.30081 15.2355 8.4888C15.2284 8.49659 15.2212 8.50425 15.2137 8.51178L11.9016 11.8611C11.6414 12.1242 11.2172 12.1266 10.9541 11.8664C10.691 11.6062 10.6886 11.182 10.9488 10.9189L13.1331 8.71H1.33797C0.967938 8.71 0.667969 8.41004 0.667969 8.04C0.667969 7.66997 0.967938 7.37 1.33797 7.37H13.1331Z'
      />
    </SvgIcon>
  )
}
