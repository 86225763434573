import {TextField, Theme, MenuItem} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

/**
 * Can be used as a text field, or a select field by passing
 * the `selectOptions` prop
 */
export function FormField(props: FormFieldProps) {
  const {selectOptions, label, value, helperText, onTextChange, ...rest} = props
  const classes = useStyles()

  return (
    <TextField
      {...rest}
      select={!!selectOptions}
      color='secondary'
      size='small'
      className={classes.textField}
      variant='filled'
      fullWidth
      label={label}
      value={value}
      helperText={helperText}
      onChange={e => onTextChange(e.target.value)}
    >
      {renderMenuItems(selectOptions)}
    </TextField>
  )
}

export type FormFieldProps = {
  selectOptions?: string[] | {[value: string]: string}
  label: string
  value: string
  helperText?: string
  disabled?: boolean
  defaultValue?: string
  type?: string
  onTextChange: (val: string) => void
}

/**
 * Accepts either an array of strings to use as select options
 * or an object whose keys are the select values, and the key-values
 * are the strings to display in the UI for each option
 */
function renderMenuItems(options: FormFieldProps['selectOptions']) {
  if (!options) return null

  if (Array.isArray(options)) {
    return options.map(item => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }

  return Object.entries(options).map(([value, displayText]) => (
    <MenuItem key={value} value={value}>
      {displayText}
    </MenuItem>
  ))
}
