import React from 'react'
import {Theme, Button, Stack, Box} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
import {LoadingButton} from '../../../components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export function Form(props: FormProps) {
  const {children, onCancel, onSave, isSaving, error} = props
  const classes = useStyles()

  return (
    <Box className={classes.formBox}>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          {children}
          {error && <div className={classes.error}>{error}</div>}
        </div>
      </form>
      <Stack direction='row' justifyContent='flex-end'>
        <Button
          className={classes.cancelButton}
          onClick={onCancel}
          disabled={isSaving}
        >
          Cancel
        </Button>

        <LoadingButton
          className={classes.saveButton}
          onClick={onSave}
          color='secondary'
          variant='contained'
          isLoading={isSaving}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export type FieldObject = {
  label: string
  value: string
  helperText: string
}

export type FormProps = {
  children: React.ReactNode
  isSaving?: boolean
  onSave: () => void
  onCancel: () => void
  error?: string
}
