import {useMutation, useQueryClient} from 'react-query'
import {appRegApi} from '../../api-interface'
import {getReportsKey, getLinkedReportsByAppRegKey} from '../queries'
import { useRecoilValue } from 'recoil';
import { licenseAtom } from '../../state';

type LinkReportsToAppRegPayload = {appRegId: string; reportIds: string[]}

export function useLinkReportsToAppReg() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: LinkReportsToAppRegPayload) =>
      appRegApi.linkReportsToAppReg(payload.appRegId, payload.reportIds, licenseId),
    {
      onSettled() {
        queryClient.invalidateQueries(getReportsKey)
        queryClient.invalidateQueries(getLinkedReportsByAppRegKey)
      },
    }
  )
}
