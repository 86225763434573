import {Theme} from '@mui/material'

import {makeStyles} from '@mui/styles'
import {useParams} from 'react-router-dom'
import {PbiReport} from './pbi-report'
import {useGetReports} from '../../../hooks'
import {isOnlyOneUniqueItem} from '../util'
import {FullPageLoader, Layout} from '../../../components'

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {display: 'flex', justifyContent: 'space-between'},

  embedWrapper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 40px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
      width: '100%',
    },
  },

  embeddedReport: {
    '& iframe': {
      border: 'none',
    },
    flex: 1,
  },
}))

export function ReportView(props: ReportViewProps) {
  const classes = useStyles()
  const routeParams = useParams<any>()
  const reportId = routeParams.id
  const reportName = routeParams.name
  const {data: reports, isLoading} = useGetReports()

  const ownedReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const allReports = [...ownedReports, ...sharedReports, ...defaultReports]

  //console.log(allReports)
  const showBackButton = !isOnlyOneUniqueItem(allReports)
  //console.log('showBackButton', showBackButton)
  if (isLoading) {
    return <FullPageLoader />
  }

  return (
    <Layout
      title={reportName}
      showBackButton={showBackButton}
      fullWidth
      hideLogo
      hideBackground
    >
      <div className={classes.embedWrapper}>
        <PbiReport
          reportId={reportId}
          embedType='report'
          className={classes.embeddedReport}
        />
      </div>
    </Layout>
  )
}

type ReportViewProps = {}
