import {useEffect, useState, useRef} from 'react'
import {PowerBIEmbed} from 'powerbi-client-react'
import {models, Report} from 'powerbi-client'

import {EmbedAccess} from '../../../models'
import {powerBiApi} from '../../../api-interface'
import {FullPageLoader} from '../../../components'

export function PbiReport(props: PbiReportProps) {
  const {className, reportId} = props

  const reportRef = useRef<Report>()
  const [embedAccess, setEmbedAccess] = useState<EmbedAccess>(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    async function fetchEmbedAccess() {
      try {
        let embedAccess = await powerBiApi.embedAccess(reportId)
        setEmbedAccess(embedAccess)
      } catch (e) {
        let message = e.message
        if (e.message === 'Report Access Error') {
          message =
            'There was an issue authenticating access for this Report.  If this persists, try updating the App Registration associated with this report.'
        }
        setErrorMessage(message)
        return
      }
    }

    fetchEmbedAccess()
  }, [reportId])

  if (!embedAccess) {
    return (
      <div className={className}>
        {errorMessage ? errorMessage : <FullPageLoader />}
      </div>
    )
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report', // Supported types: report, dashboard, tile, visual and qna
        id: embedAccess.id,
        embedUrl: embedAccess.embedUrl,
        accessToken: embedAccess.accessToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            pageNavigation: {
              visible: true,
              position: 1,
            },
          },
        },
      }}
      eventHandlers={
        new Map([
          [
            'loaded',
            function () {
              console.info('Report loaded')
            },
          ],
          [
            'rendered',
            function () {
              console.info('Report rendered')
            },
          ],
          [
            'error',
            function (event) {
              console.error(event.detail)
            },
          ],
        ])
      }
      cssClassName={className}
      getEmbeddedComponent={embeddedReport => {
        reportRef.current = embeddedReport as Report
      }}
    />
  )
}

export type PbiReportProps = {
  reportId: string
  className?: string
  requiresRls?: boolean
  embedType?: 'report' | 'dashboard' | 'tile' | 'visual' | 'qna'
}
