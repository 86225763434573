import React, {useState} from 'react'
import {Box, Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

import cs from 'classnames'

import {MainAppBar} from './main-app-bar'
import {ResponsiveDrawer} from './responsive-drawer'
import {NavigationLinks} from './navigation-links'
import {useGetLicense} from '../../hooks'

const pageWidth = 1280
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'auto',
    },
    appBarOffset: {
      [theme.breakpoints.up('md')]: {
        height: '64px',
      },
      [theme.breakpoints.down('md')]: {
        height: '40px',
      },
    },
    main: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      flexGrow: 1,
      // padding: theme.spacing(3),
      overflow: 'hidden',
    },
    scrollable: {
      overflow: 'auto',
    },
    fullWidth: {
      width: '100% !important',
      maxWidth: 'unset !important',
      padding: '0 !important',
    },
    mainOpen: {
      // [theme.breakpoints.up('lg')]: {
      //   transition: theme.transitions.create('margin', {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      //   marginLeft: '273px',
      // },
    },
    hideOnMobile: {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    content: {
      maxWidth: `${pageWidth}px`,
      height: 'calc(100% - 64px)',
      margin: '0 auto',
    },
  })
)

export function Layout(props: LayoutProps) {
  const {
    children,
    title,
    showBackButton,
    fullWidth,
    hideLogo,
    hideBackground,
    scrollable,
    ...rest
  } = props
  const classes = useStyles()
  const {data: license} = useGetLicense()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(o => !o)

  return (
    <Box {...rest} className={classes.root}>
      <MainAppBar
        toggleOpen={license?.isAdmin ? toggleOpen : undefined}
        showButtons={!!license}
        showBackButton={showBackButton}
        title={title}
        fullWidth={fullWidth}
        pageWidth={pageWidth}
        hideLogo={hideLogo}
      />
      <ResponsiveDrawer toggleOpen={toggleOpen} isOpen={isOpen}>
        <NavigationLinks />
      </ResponsiveDrawer>
      <main
        className={cs(classes.main, {
          [classes.mainOpen]: isOpen,
          [classes.scrollable]: scrollable,
        })}
      >
        {!hideBackground && <Background />}
        <Box className={classes.appBarOffset} />

        <Box
          className={cs(classes.content, {
            [classes.fullWidth]: fullWidth,
          })}
        >
          {children}
        </Box>
      </main>
    </Box>
  )
}

function Background() {
  const classes = useStyles()
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
      }}
    >
      {/* Top Right half circle */}
      <svg
        width='439'
        height='876'
        viewBox='0 0 439 876'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{position: 'absolute', top: -290, right: 0}}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M439 219C317.773 219 219.5 317.049 219.5 438C219.5 558.951 317.773 657 439 657L439 876C196.546 876 7.98119e-07 679.902 1.1372e-05 438C2.19459e-05 196.098 196.546 -1.0598e-05 439 0L439 219Z'
          fill='#EDEDED'
        />
      </svg>
      <svg
        width='229'
        height='229'
        viewBox='0 0 229 229'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{position: 'absolute', top: 647, right: 300}}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M114.5 0C177.737 0 229 51.2594 229 114.498C229 177.741 177.737 229 114.5 229C51.2631 229 0 177.741 0 114.498C0 51.2594 51.2631 0 114.5 0Z'
          fill='#EDEDED'
        />
      </svg>
      {/* Bottom left half circle */}
      <svg
        width='876'
        height='439'
        viewBox='0 0 876 439'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.hideOnMobile}
        style={{position: 'absolute', bottom: 0, left: '-419px'}}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M657 439C657 317.773 558.951 219.5 438 219.5C317.049 219.5 219 317.773 219 439L0 439C-2.1196e-05 196.546 196.098 8.99565e-05 438 6.88088e-05C679.902 4.7661e-05 876 196.546 876 439L657 439Z'
          fill='#EDEDED'
        />
      </svg>
      <svg
        width='229'
        height='229'
        viewBox='0 0 229 229'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.hideOnMobile}
        style={{position: 'absolute', bottom: 555, left: '279px'}}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M114.5 0C177.737 0 229 51.2594 229 114.498C229 177.741 177.737 229 114.5 229C51.2631 229 0 177.741 0 114.498C0 51.2594 51.2631 0 114.5 0Z'
          fill='#EDEDED'
        />
      </svg>
    </Box>
  )
}

type LayoutProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  title?: string
  showBackButton?: boolean
  fullWidth?: boolean
  scrollable?: boolean
  hideLogo?: boolean
  hideBackground?: boolean
}
