import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {licenseApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getLicenseQueryKey = 'get-license'

export function useGetLicense() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getLicenseQueryKey, licenseId],
    () => licenseApi.getLicense(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
