import {Theme, Typography} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

import {MainAppBar} from '../components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subtitle: {
      margin: theme.spacing(2),
      width: '800px',
    },
  })
)

export function UserNotInvitedPage() {
  const classes = useStyles()

  return (
    <>
      <MainAppBar title='' showButtons fullWidth pageWidth={1280} />

      <div className={classes.centered}>
        <Typography variant='h3' color='secondary'>
          No reports found
        </Typography>

        <Typography variant='body1' align='center' className={classes.subtitle}>
          Sorry, Looks like we can't find any reports that have been shared to
          you. Please contact your administrator to get access to reports.
        </Typography>
      </div>
    </>
  )
}
