import {ReportConnectionStatus} from '../../../../models'

export const connectionStatusInfo = {
  [ReportConnectionStatus.OK]: {
    type: 'success',
    title: 'Connection Success',
  },

  // This means that the url entered did not produce a valid workspace id or report id
  [ReportConnectionStatus.BadRequest]: {
    type: 'error',
    title: 'Connection Failed',
    message: 'Verify that the report url is accurate',
  },

  // This means that the App registration was not able to get an AD token
  [ReportConnectionStatus.Unauthorized]: {
    type: 'error',
    title: 'Connection Failed',
    message:
      'The app registration selected was unable to authenticate with Azure Active Directory.  Ensure that the App Registration is set up properly.',
  },

  // This means we that the App Registration was able to get a AD token, but does not have access
  // to the report.  This could mean that the report url is bad, or the app registration report
  // hasn't been given access to the report.
  [ReportConnectionStatus.Forbidden]: {
    type: 'error',
    title: 'Connection Failed',
    message: 'Connection failure may have occurred because of the following:',
    reasons: [
      'App Registration may not be associated with the Power BI workspace',
      'App Registration may not have proper API permissions',
      'Report details (url) may be invalid',
    ],
  },
  // Using rls since the response is also 200
  [ReportConnectionStatus.RowLevelSecurity]: {
    type: 'warning',
    title: 'Connection Successful. Report dataset requires row level security',
  },
}
