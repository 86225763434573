import {Configuration, LogLevel, PopupRequest} from '@azure/msal-browser'

//redb2cdev01.b2clogin.com/redb2cdev01.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_susi&client_id=a54b6135-07c5-4e0a-b64f-a37be8db6ea4&nonce=defaultNonce&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Freports&scope=openid&response_type=id_token&prompt=login
const tenant = 'redb2cdev01.onmicrosoft.com'
const clientId = 'a54b6135-07c5-4e0a-b64f-a37be8db6ea4'
const azureB2CHostname = 'redb2cdev01.b2clogin.com'
const authorityBase = `https://${azureB2CHostname}/${tenant}`
const authority = `${authorityBase}/B2C_1A_signup_signin_carlo`
// export const authority = "https://login.microsoftonline.com/common"
const readScope =
  'https://redb2cdev01.onmicrosoft.com/a54b6135-07c5-4e0a-b64f-a37be8db6ea4/read'

export const configuration: Configuration = {
  auth: {
    clientId,
    authority,
    knownAuthorities: [azureB2CHostname],
    postLogoutRedirectUri: '/',
    // knownAuthorities: ['https://login.microsoftonline.com/common'],
  },
  // Uncomment the following to get logging from msal
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
      piiLoggingEnabled: false,
    },
  },
}

export const loginRequest: PopupRequest = {
  scopes: [readScope],
}
