import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {NewUser, User} from '../../models'
import {licenseAtom} from '../../state'
import {getOtherUsersOnLicenseKey} from '../queries'

type UpdateUserContext = {
  previousUsers: User[]
}

type UpdateUsersPayload = {
  user: NewUser
}

export function useUpdateUser() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: UpdateUsersPayload) =>
      userApi.updateUser(payload.user, licenseId),
    {
      async onMutate(payload): Promise<UpdateUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnLicenseKey, licenseId])

        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnLicenseKey,
          licenseId,
        ])

        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          (old: User[]) =>
            old.map(user => {
              if (user.email === payload.user.email) {
                return payload.user
              }
              return user
            })
        )

        return {previousUsers}
      },
      onError(_err, _updatedUser, context: UpdateUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getOtherUsersOnLicenseKey, licenseId])
      },
    }
  )
}
