import {CircularProgress, Stack, Theme, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {ReportTile} from './report-tile'
import {Box} from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  noReports: {
    marginTop: theme.spacing(10),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
}))

export function ReportTiles(props: ReportTileProps) {
  const {isLoading, reports, title, subtitle} = props
  const classes = useStyles()

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  if (!reports || !reports.length) {
    return (
      <Typography className={classes.noReports} variant='body1'>
        No reports to display
      </Typography>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5.5,
      }}
    >
      <Stack direction='row' alignItems='baseline' gap={1}>
        <Typography variant='h5' sx={{fontWeight: 600, display: 'block'}}>
          {title}
        </Typography>
        {subtitle && (
          <Typography sx={{fontWeight: 400}}>({subtitle})</Typography>
        )}
      </Stack>

      <Stack direction='row' flexWrap='wrap' sx={{mt: 3}} gap={3}>
        {reports.map(report => {
          return (
            <ReportTile
              key={`${title}-${report.id}`}
              title={report.name}
              description={report.description}
              id={report.id}
            />
          )
        })}
      </Stack>
    </Box>
  )
}

type ReportTileProps = {
  isLoading: boolean
  title: string
  reports: any[]
  subtitle?: string
}
