import {useEffect, useState} from 'react'
import {
  Button,
  Theme,
  Typography,
  // Switch,
  // FormControlLabel,
  // FormGroup,
  Stack,
  useTheme,
  alpha,
  Paper,
} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
import {ColorPickerField} from './color-picker-field'
import {
  useCreateTheme,
  useGetAppSettings,
  useGetLicense,
  useGetTheme,
} from '../../../../hooks'
import {defaultTheme} from '../../../../utils/theme'
import {FullPageLoader} from '../../../../components'
import {PowerBITheme} from '../../../../models'
import {ImageSelector} from './image-selector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    addLogo: {
      mt: 1,
    },
    addLogoBox: {
      height: '300px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    secondaryColor: {
      color: theme.palette.secondary.main,
    },
    currentLogo: {
      padding: theme.spacing(2),
      borderRadius: '5px',
      maxWidth: '175px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    themeBox: {
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: 'space-between',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
    },
    themeContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

export function AppearanceConfig() {
  const classes = useStyles()
  const {mutate: saveTheme} = useCreateTheme()
  const {data: appSettings} = useGetAppSettings()

  const {data: themeForLicense, isLoading: themeLoading} = useGetTheme()
  const {data: license, isLoading: licenseLoading} = useGetLicense()

  const isLoading = themeLoading || licenseLoading

  const currentTheme = useTheme()

  const [primaryColor, setPrimaryColor] = useState(
    currentTheme.palette.primary.main
  )
  const [secondaryColor, setSecondaryColor] = useState(
    currentTheme.palette.secondary.main
  )
  const [successColor, setSuccessColor] = useState(
    currentTheme.palette.success.main
  )
  const [warningColor, setWarningColor] = useState(
    currentTheme.palette.warning.main
  )
  const [backgroundColor, setBackgroundColor] = useState(
    currentTheme.palette.background.default
  )
  const [errorColor, setErrorColor] = useState(currentTheme.palette.error.main)
  // const [mode, setMode] = useState(currentTheme.palette.mode)

  const [logo, setLogo] = useState(themeForLicense?.logo)

  useEffect(() => {
    setPrimaryColor(currentTheme.palette.primary.main)
    setSecondaryColor(currentTheme.palette.secondary.main)
    setSuccessColor(currentTheme.palette.success.main)
    setWarningColor(currentTheme.palette.warning.main)
    setErrorColor(currentTheme.palette.error.main)
    setBackgroundColor(currentTheme.palette.background.default)
    if (themeForLicense?.logo) setLogo(themeForLicense.logo)
  }, [currentTheme, themeForLicense])
  async function resetToDefault() {
    saveTheme({licenseNodeId: license.id, theme: defaultTheme})
  }
  async function updateTheme() {
    const newTheme: PowerBITheme = {
      palette: {
        // mode: mode,
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
        success: {
          main: successColor,
        },
        warning: {
          main: warningColor,
        },
        error: {
          main: errorColor,
        },
        background: {
          default: backgroundColor,
        },
      },

      typography: {
        button: {
          textTransform: 'capitalize',
        },
      },
      logo: logo,
    }

    saveTheme({
      licenseNodeId: license.id,
      theme: newTheme,
    })
  }
  // function toggleDarkMode(isDark: boolean) {
  //   setMode(isDark ? 'dark' : 'light')
  // }
  // const isDarkMode = mode === 'dark'

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Appearance</Typography>
      <Typography variant='body2' sx={{marginBottom: 2}}>
        Change the theme settings
      </Typography>
      {isLoading && <FullPageLoader />}
      {!isLoading && (
        <div>
          <div className={classes.themeContainer}>
            <Paper className={classes.themeBox}>
              <Typography variant='h6'>Theme Colors</Typography>
              <ColorPickerField
                title='Primary Color'
                selectedColor={primaryColor}
                onColorChanged={color => {
                  setPrimaryColor(color)
                }}
              />
              <ColorPickerField
                title='Secondary Color'
                selectedColor={secondaryColor}
                onColorChanged={color => {
                  setSecondaryColor(color)
                }}
              />
              <ColorPickerField
                title='Background'
                selectedColor={backgroundColor}
                onColorChanged={color => {
                  setBackgroundColor(color)
                }}
              />
            </Paper>
            <Paper className={classes.themeBox}>
              <Typography variant='h6'>Status Colors</Typography>
              <ColorPickerField
                title='Success'
                selectedColor={successColor}
                onColorChanged={color => {
                  setSuccessColor(color)
                }}
              />
              <ColorPickerField
                title='Warning'
                selectedColor={warningColor}
                onColorChanged={color => {
                  setWarningColor(color)
                }}
              />
              <ColorPickerField
                title='Error'
                selectedColor={errorColor}
                onColorChanged={color => {
                  setErrorColor(color)
                }}
              />
            </Paper>
            {appSettings?.customLogoEnabled && (
              <Paper className={classes.themeBox}>
                <div>
                  <Typography variant='h6'>Theme Logo</Typography>

                  {logo && (
                    <div
                      className={classes.currentLogo}
                      style={{backgroundColor: primaryColor}}
                    >
                      <img
                        src={logo}
                        alt='Logo'
                        style={{width: '150px', marginBottom: 2}}
                      />
                    </div>
                  )}

                  {!logo && (
                    <Typography variant='body2' sx={{m: 2}} fontStyle='italic'>
                      No logo set
                    </Typography>
                  )}
                </div>

                <ImageSelector
                  setLogo={setLogo}
                  logo={logo}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </Paper>
            )}
          </div>
          <Stack direction='row' gap={1}>
            <Button
              sx={{mt: 2}}
              style={{color: primaryColor, borderColor: primaryColor}}
              onClick={() => updateTheme()}
              size='large'
              variant='outlined'
            >
              Save Theme
            </Button>
            <Button
              sx={{mt: 2}}
              style={{color: secondaryColor}}
              onClick={() => resetToDefault()}
              size='large'
            >
              Reset Theme
            </Button>
          </Stack>
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={isDarkMode}
                  onChange={e => {
                    toggleDarkMode(e.target.checked)
                  }}
                />
              }
              label='Dark mode'
            />
          </FormGroup> */}
        </div>
      )}
    </div>
  )
}
