import {useQuery} from 'react-query'
import {appRegApi} from '../../api-interface'

export const getLinkedReportsByAppRegKey = 'get-linked-reports-by-app-reg'

export function useGetLinkedReportsByAppReg(appRegId: string) {
  return useQuery(
    [getLinkedReportsByAppRegKey, appRegId],
    () => appRegApi.getLinkedReportsByAppReg(appRegId),
    {
      enabled: !!appRegId,
    }
  )
}
