import {useMutation, useQueryClient} from 'react-query'
import {appRegApi} from '../../api-interface'
import {getReportsKey, getLinkedReportsByAppRegKey} from '../queries'

export type UnlinkReportsFromAppRegPayload = {
  appRegId: string
  reportId: string
}

export function useUnlinkReportFromAppReg() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: UnlinkReportsFromAppRegPayload) =>
      appRegApi.unlinkReportFromAppReg(payload.appRegId, payload.reportId),
    {
      onSettled() {
        queryClient.invalidateQueries(getReportsKey)
        queryClient.invalidateQueries(getLinkedReportsByAppRegKey)
      },
    }
  )
}
